<template>
  <div style="white-space: pre-line">   
    {{errorStatus}}
  </div>
</template>
  
  <script>
  
  export default {  
    computed: {
      errorStatus() {
        return this.$store.state.caseStore.errorStatus || "Keine Fehler gefunden"
      }                           
    }
  };
  </script>