<template>
  <v-app>
    <v-container fluid class="form">
      <v-row>
        <v-col align-self="center" cols="6">
          <v-form ref="caseForm" @submit="onSubmit">
            <v-card shaped>
              <v-card-text>
                <CaseFields />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="mb-3 mr-3"
                  color="primary"
                  id="loginSubmitButton"
                  type="submit"
                  @click="onSubmit"
                >
                  Anonymisieren
                </v-btn>   
                <div>
                  {{eta}}       
                </div>       
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col cols="3">
          <v-card shaped>
            <v-card-text>
              <StatusFields />
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-btn
              class="mb-3 mr-3"
              color="primary"
              id="loginSubmitButton"
              type="submit"
              @click="refreshStatus"
            >
              Status aktualisieren
            </v-btn>  
          </v-card-actions>
        </v-col>
        <v-col cols="3">
          <v-card shaped>
            <v-card-text>
              <ErrorStatusFields />
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-btn
              class="mb-3 mr-3"
              color="primary"
              id="loginSubmitButton"
              type="submit"
              @click="refreshErrorStatus"
            >
              ErrorStatus aktualisieren
            </v-btn>      
          </v-card-actions>     
        </v-col>        
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import CaseFields from "@/components/case/CaseFields";
import StatusFields from "@/components/case/StatusFields";
import ErrorStatusFields from "@/components/case/ErrorStatusFields";

export default {
   components: {
    CaseFields,
    StatusFields,
    ErrorStatusFields
  },
  created() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },  
  computed: {
    eta() {
      return this.$store.state.caseStore.eta || ""
    },
    jwtToken() {
      return this.$store.state.auth.jwtToken;
    },
  },
  methods: {
    splitIntoObject(data) {
      data = data.split(";")
      let objectData = {}
      data.forEach((date, index) => {
        if (date !== '') {
          objectData['xa'+index] = date.trim()
        }
      })
      return objectData
    },
    async onSubmit(event) {
      event.preventDefault()
      if (this.$refs.caseForm.validate()) {
        const recaptchaCode = await this.$recaptcha("case");
        let data = this.$store.state.form.values.caseForm
        const additionalData = data.additionalData ? this.splitIntoObject(data.additionalData) : ""
        const referenceNumber = data.referenceNumber
        const ratio = data.ratio || 0.8
        delete data.additionalData
        delete data.referenceNumber
        let words = additionalData ? {...data, ...additionalData} : {...data}
        data.referenceNumber = referenceNumber.trim()
        Object.keys(words).forEach(w => words[w] = words[w].trim())
        const values = {
          data: {
            words,
            lines: {
              "head":"Ordnungsmerkmal"
            }
          },
          recaptchaCode,
          referenceNumber,
										ratio
        };
        this.$store
        .dispatch("anonData", values)
        .then(() => {
          this.refreshStatus()
        })       
      }
    },
    refreshStatus() {
      this.$store
      .dispatch("getStatus", this.$store.state.form.values.caseForm?.referenceNumber)
    },
    refreshErrorStatus() {
      this.$store
      .dispatch("getErrorStatus")
      .then(() => {

      })
    },
  },  
  watch: {
    jwtToken(value) {
      if (!value) {
        this.$router.push("/login");
      }
    },
  }
}
</script>
