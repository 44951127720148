<template>
  <div style="white-space: pre-line">   
    {{status}}    
  </div>
</template>
  
<script>
  export default {  
    computed: {
      status() {
        return this.$store.state.caseStore.status || ""
      },
    }
  };
</script>