<template>
  <div>
    <v-text-field
      id="caseReferenceNumberInput"
      label="Fallnummer *"
      outlined
      v-model="referenceNumber"
      :rules="referenceNumberRules"
      autofocus
      validate-on-blur
      hint="Bitte darauf achten, dass die Unterlagen im AnonOriginalUL Ordner liegen"
    />
    Daten zum schwärzen
    <v-text-field
      id="caseFirstNameInput"
      label="Vorname *"
      outlined
      :rules="firstNameRules"
      v-model="firstName"
      validate-on-blur
    />
    <v-text-field
      id="caseLastNameInput"
      label="Nachname *"
      outlined
      :rules="lastNameRules"
      v-model="lastName"
      validate-on-blur
    />
    <v-text-field
      id="caseStreetInput"
      label="Straße"
      outlined
      v-model="street"
      validate-on-blur
    />    
    <v-text-field
      id="caseHouseNumberInput"
      label="Hausnummer"
      outlined
      v-model="houseNumber"
      validate-on-blur
    />    
    <v-text-field
      id="caseZipCodeInput"
      label="PLZ"
      outlined
      v-model="zipCode"
      validate-on-blur
    />        
    <v-text-field
      id="caseCityInput"
      label="Stadt"
      outlined
      v-model="city"
      validate-on-blur
    />    
    <v-text-field
      id="caseInsuranceNumberInput"
      label="Versicherungsnummer"
      outlined
      v-model="insuranceNumber"
      validate-on-blur
    />     
    <v-textarea
      label="Weitere Daten mit ; getrennt (z.B. Namen der Tochter; AKV Bearbeitungsnummer;)"
      outlined
      dense
      v-model="additionalData"
      :rows="3"
      hint="Obere Zeile mit Ordnungsmerkmal wird automatisch geschwärzt"
    />  
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
								Vergleichsrate [ ? ]
								</span>
      </template>
      <span>Gibt an, wie ähnlich die Wörter zu einander sein sollen. Um so höher der Wert, desto ähnlicher müssen die Wörter sein, bevor sie geschwärzt werden.</span>
    </v-tooltip>				  
			<v-radio-group
					v-model="ratio"
					row
			>				
			<v-radio
					label="80%"
					value="0.8"
			></v-radio>				
			<v-radio
					label="75%"
					value="0.75"
			></v-radio>
			<v-radio
					label="66%"
					value="0.66"
			></v-radio>			
			</v-radio-group>
  </div>
</template>

<script>
import change from "@/helper/change";

export default {
  data() {
    return {
      firstNameRules: [
        (value) => !!value || "Bitte geben Sie einen Vornamen ein."
      ],
      lastNameRules: [
        (value) => !!value || "Bitte geben Sie einen Nachnamen ein."
      ],
      referenceNumberRules: [
        (value) => !!value || "Bitte geben Sie eine Fallnummer ein."
      ],
    }
  },  
  computed: {
    referenceNumber: {
      get() {
        return this.$store.state.form.values.caseForm?.referenceNumber;
      },
      set(value) {
        change(this.$store, "caseForm", "referenceNumber", value);
      },
    },
    firstName: {
      get() {
        return this.$store.state.form.values.caseForm?.firstName;
      },
      set(value) {
        change(this.$store, "caseForm", "firstName", value);
      },
    },
    lastName: {
      get() {
        return this.$store.state.form.values.caseForm?.lastName;
      },
      set(value) {
        change(this.$store, "caseForm", "lastName", value);
      },
    }, 
    street: {
      get() {
        return this.$store.state.form.values.caseForm?.street;
      },
      set(value) {
        change(this.$store, "caseForm", "street", value);
      },
    },  
    houseNumber: {
      get() {
        return this.$store.state.form.values.caseForm?.houseNumber;
      },
      set(value) {
        change(this.$store, "caseForm", "houseNumber", value);
      },
    },   
    zipCode: {
      get() {
        return this.$store.state.form.values.caseForm?.zipCode;
      },
      set(value) {
        change(this.$store, "caseForm", "zipCode", value);
      },
    },
    city: {
      get() {
        return this.$store.state.form.values.caseForm?.city;
      },
      set(value) {
        change(this.$store, "caseForm", "city", value);
      },
    },
    insuranceNumber: {
      get() {
        return this.$store.state.form.values.caseForm?.insuranceNumber;
      },
      set(value) {
        change(this.$store, "caseForm", "insuranceNumber", value);
      },
    }, 
    additionalData: {
      get() {
        return this.$store.state.form.values.caseForm?.additionalData;
      },
      set(value) {
        change(this.$store, "caseForm", "additionalData", value);
      },
    },
    ratio: {
      get() {
        return this.$store.state.form.values.caseForm?.ratio || "0.8";
      },
      set(value) {
        change(this.$store, "caseForm", "ratio", value);
      },
    },				                            
  }
};
</script>